<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.feedbacksByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="feedbacksHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="feedbacks"
            :search="searchFeedbackString"
            class="elevation-1 cursor-pointer"
            @click:row="showDetails"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.feedback') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchFeedbackString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showDetails(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-arrow-expand</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.show') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.show') }}</span>
                  </v-tooltip>

                  <v-tooltip left v-if="item.file_path">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="downloadFile(item)">
                        <v-icon v-if="!item.active" class="mx-1" small v-bind="attrs">mdi-download</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.downloadFile') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.downloadFile') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="detailsModal" max-width="700px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ feedbackUser }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="detailsModal = false"></v-icon>
        </v-card-title>

        <v-card-text>
          <v-container>
            <h3>Subject: {{ feedbackSubject }}</h3>
            <br />
            <p>{{ feedbackBody }}</p>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { feedbacksHeaders } from '@/mixins/data-table-headers';

export default {
  name: 'FeedbacksPage',
  components: {},
  data: () => ({
    searchFeedbackString: '',
    feedbacks: [],
    detailsModal: false,
    showDetailsModal: false,
    feedbackUser: '',
    feedbackBody: '',
    feedbackSubject: '',
  }),
  created() {
    this.loadAllFeedbacks();
  },
  computed: {
    feedbacksHeaders() {
      return feedbacksHeaders(i18n);
    },
  },
  methods: {
    async loadAllFeedbacks() {
      await this.$store.dispatch('feedbacks/getAllFeedbacks').then((res) => {
        this.feedbacks = res.data;
      });
    },

    showDetails(item) {
      this.detailsModal = true;
      this.feedbackUser = item?.user?.profile?.full_name;
      this.feedbackBody = item.body;
      this.feedbackSubject = item.subject;
    },

    closeShowDetailsModal() {
      this.detailsModal = false;
    },

    downloadFile(item) {
      let file = document.createElement('a');

      if (!item.file_path.includes("https://") && !item.file_path.includes("http://")) {
        file.href = process.env.VUE_APP_BACKEND_URL + item.file_path;
      }else{
        file.href = item.file_path;
      }

      file.setAttribute('target', '_blank');
      document.body.appendChild(file);
      file.click();
      document.body.removeChild(file);
    },
  },

  watch: {
    detailsModal(val) {
      val || this.closeShowDetailsModal();
    },
  },
};
</script>
